$(document).ready(function(){

    $('.start-btn').click(function () {
        $('.message-btn').toggleClass('active');
    });

    $(".lang_block").click(function(e) {
        $(this).toggleClass("open");
        e.stopPropagation();
    });
    $("body").click(function() {
        $(".lang_block").removeClass('open');
    });


    $(".hamburger").click(function(e) {
        $('.scrolled_menu').toggleClass("active");
        $(".full_menu_wrapp").toggleClass('open');
        $('body').toggleClass("over_h");
    });
});


$('.popup-with-move-anim').magnificPopup({
    type: 'inline',

    fixedContentPos: false,
    fixedBgPos: true,

    overflowY: 'auto',
    fixedContentPos: 'true',
    closeBtnInside: true,
    preloader: false,

    midClick: true,
    removalDelay: 300,
    mainClass: 'my-mfp-slide-bottom'
});

$('.mfp-close').click(function () {
    $.magnificPopup.close();
})

$(document).ready(function() {
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });
});
$('.horizontal-scroll-body ').slick({
    infinite: false,
    slidesToShow: 1,
    arrow: false,
    nav: false,
    slidesToScroll: 1,
    variableWidth: true,
});




$(document).ready(function() {
    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
        }
    });
});



$('.filter-portf').click(function (e) {
    e.preventDefault();
    $('.filter-portfolio_container').toggleClass('active');
})

$('.filter-portfolio_container .close').click(function () {
    $('.filter-portfolio_container').toggleClass('active');

})